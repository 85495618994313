// src/components/TermsOfService.js

import React from 'react';
import './TermsOfService.css'; // Import the CSS file for the styles
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const TermsOfService = () => {
  const navigate = useNavigate(); // Hook to get the navigate function

  const goHome = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <div className="tos-container">
      <h1>Terms of Service</h1>
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By purchasing, installing, or using the chatbot platform ("Platform") developed by [Your Company Name] ("we", "us", "our"), you agree to abide by these Terms of Service. If you do not agree with these terms, you should not purchase or use the Platform.</p>
      </section>
      <section>
        <h2>2. Service Overview</h2>
        <p>The Platform allows website developers to own and deploy their own chatbot platform without relying on third-party services. You will have full control over the chatbot, including customization, deployment, and maintenance.</p>
      </section>
      <section>
        <h2>3. User Eligibility</h2>
        <p>To use the Platform, you must be a website developer or business that is legally permitted to enter into contracts and use software in your jurisdiction.</p>
      </section>
      <section>
        <h2>4. Pricing and Payment</h2>
        <p>- <strong>Ownership Packages:</strong> We offer two types of ownership packages: Package 1 (One-time payment with no maintenance) and Package 2 (One-time payment with ongoing support, maintenance, and updates).</p>
        <p>- <strong>Refunds:</strong> No refunds are offered after the delivery of the Platform.</p>
      </section>
      
      {/* Button at the center bottom to go back to the homepage */}
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <button
          onClick={goHome}
          style={{
            padding: '12px 32px',
            fontSize: '1.125rem',
            backgroundColor: '#2980b9', // Blue background color
            color: 'white', // White text
            border: 'none', // Remove the border
            borderRadius: '8px', // Rounded corners
            cursor: 'pointer', // Pointer cursor on hover
            transition: 'background-color 0.3s',
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#3498db'} // Change to lighter blue on hover
          onMouseOut={(e) => e.target.style.backgroundColor = '#2980b9'} // Return to original blue when hover ends
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default TermsOfService;
