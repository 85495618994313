import React from 'react';
import { MailOpenIcon as Envelope } from 'lucide-react';
import Footer from './Footer';
import dashboard from './dashboard.png';
import chat from './chat.png';
import './LandingPage.css'; // Import the CSS file for the styles

function LandingPage() {
  return (
    <div className="landing-page">
      <header className="header">
      <div class="price-container">
  <span class="standard-price">Standard price: $365 USD per year</span> <br/>
  <span class="sale-price">pay for 6 months---get the other 6 months free</span>
</div>
      </header>

      <main className="main-content">
        <section className="intro-section">
          
          <div className="intro-content">
          
          <span className="tsiws-circle">Tsiws</span> <br/><br/>
          <h1 className="header-title2">The <br/> Ai Chatbot Store <br/></h1>

            <h2 className="intro-title">
              Put a Customer Service Agent on your Website
            </h2>
            <div className="intro-subtitle-div">
            <h3 className="intro-subtitle">
              Deliver Better Service to your Daily Customers with a Smart Assistant available 24/7
            </h3>
            </div>

          </div>



          <section className="cta-section">
          <div className="cta-content">
            
            <a href="mailto:contact@chatbotdeveloper.com" className="cta-button">
  <Envelope className="cta-icon" />
  Email Us
</a>
<h2 className="cta-title1">
              Or talk with our Ai
            </h2>
          </div>
        </section>
        </section>


  {/* ... rest of the CTA content ...

<div className="slide-in-from-left-upon-scroll-div">
  <p className='slide-in-from-right-upon-scroll-text'>simplicity</p>
  <p className='slide-in-from-right-upon-scroll-subtext' >Avoid complicated platforms and processes</p>
</div>

<div class="feature-card">

<div class="body-title-div">
  <h4 class="body-title">Own your Ai Chatbot platform in 5 easy steps</h4>
  </div>
  
  <p className='customization-item'>
    <span class="feature-sub-title">Step 1: Contact Us:</span> <br/>
    Ready to take control? Simply reach out to us and lets discuss the ease and benefits of owning your own chatbot platform.
    <br/><br/>

    <span class="feature-sub-title">Step 2: Secure Your Domain:</span> <br/> 
    Choose a unique domain name like "e-chat.com" to represent your chatbot platform.
    <br/><br/>

    <span class="feature-sub-title">Step 3: Easy Deployment:</span> <br/>
    We leverage GitHub and Render.com to effortlessly deploy your platform, no efffort required on your part.
    <br/><br/>

    <span class="feature-sub-title">Step 4: Comprehensive Testing (3 Days):</span><br/> 
    Dive in and take advantage of a 3-day trial to fully test things, integrate it with your websites, and explore its functionalities.
    <br/><br/>

    <span class="feature-sub-title">Step 5: Choose & Pay:</span> <br/>
    Once satisfied, select the ownership package that best suits your needs and complete your payment.
</p>
</div>




<div className="slide-in-from-left-upon-scroll-div">
  <p className='slide-in-from-right-upon-scroll-text'>Profitability</p>
</div>
        <section className="features-section">
        <div class="feature-card ownership-process">
        <div class="body-title-div">
  <h4 class="body-title">The range of benefits for you</h4>
  </div>

  <p className='customization-item'>
    <span class="feature-sub-title">Increased Profitability:</span> <br/>
    Recurring Revenue goes to you, rather than third-party. 
    <br/><br/>
    <span class="feature-sub-title">Tailored Pricing:</span> <br/> 
    Charge your customers what you want to charge, avoid third-party price constraints.
    <br/><br/>
    <span class="feature-sub-title">Differentiation:</span> <br/>
    Stand out from competitors by offering unique chatbot solutions.
    <br/><br/>
    <span class="feature-sub-title">Consistent Branding :</span><br/> 
    Add a chatbot that carries your brand to the websites that you develop.
    <br/><br/>
    <span class="feature-sub-title">Expanded Service Offerings:</span> <br/>
    In addition to providing websites, you now provide chatbot solutions too. 
</p>


  <div class="styled-text-container">
  <p>Never use third party Ai chatbots on your clients websites again</p>
</div>
</div>




<div className="slide-in-from-left-upon-scroll-div">
  <p className='slide-in-from-right-upon-scroll-text'>Convience</p>
</div>
<div className="feature-card explore-chatbot">
<div class="body-title-div">
  <h4 class="body-title">Give it a test</h4>
  </div>
  <p className="customization-item">
    <span class="feature-sub-title">See our Ai Chatbots in action</span><br /><br/>
    Interact with our advanced chatbot directly on this page. Ask it anything about our services, pricing, or technical capabilities.
  </p>
  <img src={chat} alt="Chatbot" className="chat-img"/>

  <p className="customization-item">
    <span class="cta-highlight">Start a conversation now and discover that this is something that you should own.</span>
  </p>
</div>
<div className="announcement-card">
<h4 className="announcement-text"><br/>Manage your chatbots from your online dashboard</h4>

<img src={dashboard} alt="Chatbot Dashboard" className="dashboard-img"/>
      <h4 className="announcement-text">Add new clients with just a few clicks <br/><br/></h4>
    </div>

    <div class="announcement-card">
        <h4 class="announcement-text"><br/>Pricing</h4> 

        <div class="pricing-card">
  <div class="category">
    <h3>Singular Platform</h3>
    <p class="details">Developed and deployed. Support up to 1000 chatbots – Render.com hosted, Postgres DB integrated, powered by OpenAI & Groq Cloud</p>
    <p class="price">$299</p>
  </div>
  <div class="category enterprise">
    <h3>Enterprise Platform Development</h3>
    <p class="details">Speak with our team for custom enterprise solutions</p>
    <button class="contact-btn">Contact Team</button>
  </div>
</div>
</div>
<div className="feature-card ownership-package">





<div class="body-title-div">
  <h4 class="body-title">Ownership Package</h4>
</div>
<p class="customization-item">
  <span class="package-title package-circle">Package 1 </span><br/> 
  One Time Payment - One Time Delivery:<br/><br />
  Receive a fully developed, deployed and functional chatbot platform, plus ongoing technical support if needed.
  (If you are already a website developer, you will be able to handle any maintenance or updates the platform would need, as it would mostly be things that are already familiar to you.)
  <br/><br/><br/><br/>
  <span class="package-title package-circle">Package 2 </span><br/> 
  One Time Payment - Ongoing Support:<br /><br/>
  Benefit from continuous updates, maintenance, and technical support. No fiddling with coding required on your part. Whatever update, enhancement, or adjustment required, you message us, we do the work.
</p>


</div>

















<div class="announcement-card">
  <h4 class="announcement-text">Avoid complex platforms with complicated configuration processes... Choose simplicity for yourself and your clients</h4>
  </div>







<div className="feature-card customization-options">
<div class="body-title-div">
  <h4 class="body-title">Customization options</h4>
  </div>
  <p className="feature-sub-title">
    Take control of your chatbot's appearance and functionality. Your platform will be able offer a range of customization options that surpass those of third-party solutions:
  </p>
  <ul className="customization-list">
    <li className="customization-item">
      <span className="customization-highlight">Custom Branding & API choices:<br/><br/></span>
      <ul className="customization-sublist">
        <li>Unique icons</li>
        <br/>
        <li>Personalized color schemes</li>
        <br/>
        <li>Tailored text greetings</li>
        <br/>
        <li>Integration with external APIs of your choice (e.g., OpenAI, Anthropic, GROQ)</li>
        <br/>
        <li>Custom rate limits</li>
      </ul>
    </li>
  </ul>
</div>

<div className="feature-card documentation">
<div class="body-title-div">
  <h4 class="body-title">Comprenhensive Documentation</h4>
  </div>
  <p className="feature-sub-title">
    We provide the in-depth documentation that you need to facilitate your ownership journey:
  </p>
  <ul className="documentation-list">
    <li className="documentation-item"><span className="documentation-highlight">Step-by-Step Guides:</span> Detailed instructions for setup, configuration, and deployment.</li>
    <br/>
    <li className="documentation-item"><span className="documentation-highlight">API Reference:</span> Comprehensive API documentation for advanced customization.</li>
    <br/>
    <li className="documentation-item"><span className="documentation-highlight">Troubleshooting Tips:</span> Quick solutions to common issues.</li>
    <br/>
    <li className="documentation-item"><span className="documentation-highlight">In-Depth Technical Documentation:</span> Access detailed documentation on the development stack, code snippets, and best practices.</li>
  </ul>
</div>

        </section>

       



        <section className="cta-section">
          <div className="cta-content">
            <h2 className="cta-title">
              Ready to elevate your clients' chatbot experience?
            </h2>
            <a href="mailto:contact@chatbotdeveloper.com" className="cta-button">
  <Envelope className="cta-icon" />
  Email Us
</a>

          </div>
        </section>
         */}
      </main>

  <Footer/>
    </div>
    
  );
  
}

 

export default LandingPage;
