// src/components/PrivacyPolicy.js

import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for the styles
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const PrivacyPolicy = () => {
  const navigate = useNavigate(); // Hook to get the navigate function

  const goHome = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <section>
        <h2>1. Data Collection</h2>
        <p>As the developer of the Platform, we do not collect any personal data from your users or your customers. The Platform may collect website data for the purpose of providing responses via the chatbot, but this data is stored and processed by the Platform itself and is under your control as the platform owner.</p>
      </section>
      <section>
        <h2>2. User Data</h2>
        <p>As the platform owner, you are responsible for determining how user data is collected, stored, and used. We do not collect any queries, chat logs, or personal information from end users of the chatbot.</p>
      </section>
      <section>
        <h2>3. Cookies and Tracking</h2>
        <p>The Platform does not use cookies for tracking or collecting data from users of the website. We do not implement cookies on your behalf.</p>
      </section>
      {/* More sections as needed */}

      {/* Button at the center bottom to go back to the homepage */}
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <button
          onClick={goHome}
          style={{
            padding: '12px 32px',
            fontSize: '1.125rem',
            backgroundColor: '#2980b9', // Blue background color
            color: 'white', // White text
            border: 'none', // Remove the border
            borderRadius: '8px', // Rounded corners
            cursor: 'pointer', // Pointer cursor on hover
            transition: 'background-color 0.3s',
          }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#3498db'} // Change to lighter blue on hover
          onMouseOut={(e) => e.target.style.backgroundColor = '#2980b9'} // Return to original blue when hover ends
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
