import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';

import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>

    </>
  );
}

export default App;