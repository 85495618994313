// FILE NAME: Footer.js
// PURPOSE: Footer component for the website or application

import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <p style={styles.text}>© {new Date().getFullYear()} Tsiws- Ai Chatbot Store. All rights reserved.</p>
        <nav style={styles.nav}>
          <a href="/about" style={styles.link}>About</a>
          <a href="/contact" style={styles.link}>Contact</a>
          <a href="/privacy-policy" style={styles.link}>Privacy Policy</a>
          <a href="/Terms" style={styles.link}>Terms of Service</a>
        </nav>
      </div>
     
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px 0',
    textAlign: 'center',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px',
  },
  text: {
    marginBottom: '10px',
  },
  nav: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
};

export default Footer;
